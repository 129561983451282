import { render, staticRenderFns } from "./cabinet-list.vue?vue&type=template&id=46edc2fc&scoped=true"
import script from "./cabinet-list.vue?vue&type=script&lang=ts"
export * from "./cabinet-list.vue?vue&type=script&lang=ts"
import style0 from "./cabinet-list.vue?vue&type=style&index=0&id=46edc2fc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46edc2fc",
  null
  
)

export default component.exports