



















































import Vue from "vue";
import { Popup, Picker, CellGroup, Cell, CheckboxGroup, Checkbox, Search, List, Button, Sticky, Toast, Dialog } from "vant";
import siteEdit from "../site/edit/edit.vue";
export default Vue.extend({
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Sticky.name]: Sticky,
    ["site-edit"]: siteEdit
  },
  data() {
    return {
      businessId: (this.$route.query.businessId as unknown) as number,
      siteId: (this.$route.query.siteId as unknown) as number,
      siteName: (this.$route.query.siteName as unknown) as string,
      keyword: "",
      searchKeyword: "",
      list: [],
      checked: [] as { id: number }[],
      showSiteAdd: false,
      showSiteAdd2: false,
      sitePicker: {
        show: false,
        list: [],
        default: 0,
        loading: true,
        keyword: "",
        act: ""
      }
    };
  },
  watch: {
    "sitePicker.show": "onSitePickerShow"
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      const requestData = {
        businessId: this.businessId,
        siteId: this.siteId,
        keyword: this.keyword
      };
      this.$axios.post("/api/manage/cabinet/getSiteCabinetList", requestData).then(res => {
        const data = res.data.data;
        this.list = data;
        this.checked = [];
      });
    },
    transfer(targetSiteId: number) {
      const cabinetIdList = [];
      for (const cabinet of this.checked) {
        cabinetIdList.push(cabinet.id);
      }
      if (cabinetIdList.length < 1) {
        Toast.fail("请选择需要迁移的设备");
        return;
      }
      const requestData = {
        siteId: targetSiteId,
        cabinetIds: cabinetIdList.join(",")
      };
      this.$axios.post("/api/manage/cabinet/updateSiteMulti", requestData).then(() => {
        Toast.success({
          message: "迁移成功",
          onClose: () => {
            this.getData();
          }
        });
      });
    },
    onSearch() {
      this.keyword = this.searchKeyword;
      this.getData();
    },
    showSiteAddPopup() {
      this.showSiteAdd = true;
      this.showSiteAdd2 = true;
    },
    onSiteAddPopupClose() {
      this.sitePicker.list = [];
      this.showSiteAdd2 = false;
    },
    toggleCheck(index: number) {
      const checkboxes = this.$refs.checkboxes as Checkbox[];
      checkboxes[index].toggle();
    },
    toggleCheckAll() {
      const checkboxGroup = this.$refs.checkboxGroup as CheckboxGroup;
      checkboxGroup.toggleAll();
    },
    checkAll() {
      const checkboxGroup = this.$refs.checkboxGroup as CheckboxGroup;
      checkboxGroup.toggleAll(true);
    },
    pickSite(act: string) {
      this.sitePicker.act = act;
      this.sitePicker.show = true;
    },
    onSitePickerShow(show: boolean) {
      if (show && this.sitePicker.list.length === 0) {
        this.loadSite();
      }
    },
    loadSite() {
      this.sitePicker.loading = true;
      const requestData = {
        businessId: this.businessId,
        isShow: -1,
        keyword: this.sitePicker.keyword
      };
      this.$axios.post("/api/manage/getSiteListForPick", requestData).then(res => {
        this.sitePicker.list = res.data.data;
        this.sitePicker.loading = false;
      });
    },
    onPickSite(value: { id: number; siteName: string }) {
      if (this.sitePicker.act == "transfer") {
        Dialog.confirm({
          message: "您确定要将这些设备迁移到" + value.siteName + "吗?"
        }).then(() => {
          this.transfer(value.id);
        });
      } else {
        this.siteId = value.id;
        this.siteName = value.siteName;
        this.getData();
      }
      this.sitePicker.show = false;
    }
  }
});
